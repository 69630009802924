.T404Page .FailureBox  {
    margin-top: 12vh;
}

.T404Page .FailureBox img {
    max-width: 70px;
}

.T404Page .PageTit2 {
    font-size: 30px;
    line-height: 1.3;
}

.T404Page .PageSub2 {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size:18px;
}

.T404Page .PageSub {
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    font-size:18px;
    word-break: keep-all;
}

.T404Page .CancelConfirm {
    gap: 0 10px;
}

.T404Page .fixed-bottom {
    width: 100%;
    max-width: 800px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
}

.T404Page .fixed-bottom .btn {
    height: 70px;
    font-size: 22px;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .T404Page .FailureBox img {
        max-width: 60px;
    }

    .T404Page .PageTit2 {
        font-size: 25px;
    }

    .T404Page .PageSub {
        font-size:15px;
    }
    .T404Page .PageSub2 {
        font-size:15px;
    }

    .T404Page .fixed-bottom .btn {
        height: 55px;
        font-size: 16px;
    }

}
